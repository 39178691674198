@font-face {
	font-family: "Work Sans";
	src: local("Work Sans"),
		url("../assets/font/WorkSans/WorkSans-VariableFont_wght.ttf")
			format("truetype");
}

* {
	font-family: Work Sans !important;
}

*::selection {
	background: #14b8a6;
}
*::-moz-selection {
	background: #14b8a6;
}
*::-webkit-selection {
	background: #14b8a6;
}

/* multi-image uploader  */
.hasImage:hover section {
	background-color: rgba(5, 5, 5, 0.4);
}
.hasImage:hover button:hover {
	background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
	opacity: 0;
}

#overlay.draggedover {
	background-color: rgba(255, 255, 255, 0.7);
}
#overlay.draggedover p,
#overlay.draggedover i {
	opacity: 1;
}

.group:hover .group-hover\:text-blue-800 {
	color: #14b8a6;
}
